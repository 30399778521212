import { render } from "preact"
import { useEffect, useRef, useState } from "preact/hooks"
import { qs, qsa } from "./utils"

export const initSearch = () => {
  const wrapper = qs("[data-search-overlay]")
  if (wrapper) {
    const action = wrapper.getAttribute("data-search-action")
    render(<SearchComponent action={action} />, wrapper)
  }
}

const SearchComponent = ({ action }) => {
  const dialog = useRef(null)
  const [value, setValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [response, setResponse] = useState(null)
  const loadResults = async (query) => {
    const response = await fetch(`${action}?query=${encodeURIComponent(query)}`)
    if (response.ok) {
      const newResponse = await response.text()
      if (newResponse.replace(/\s/g, "").length) {
        setResponse(newResponse)
      }
    }
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("search-open")
    } else {
      document.body.classList.remove("search-open")
    }
  }, [isOpen])

  useEffect(() => {
    const buttons = qsa("[data-search-toggle]")
    for (const button of buttons) {
      button.addEventListener("click", onOpen)
    }

    return () => {
      for (const button of buttons) {
        button.removeEventListener("click", onOpen)
      }
    }
  }, [])

  const debouncedLoadResults = debounce(loadResults, 200)

  const onInput = (e) => {
    if (e.target.value) {
      debouncedLoadResults(e.target.value)
    }
  }

  const onClose = (e) => {
    e.preventDefault()
    dialog.current.classList.add("closing")
  }

  const onOpen = (e) => {
    e.preventDefault()
    dialog.current.classList.add("opening")
    setIsOpen(true)
  }

  const onAnimationEnd = (e) => {
    if (e.animationName === "show") {
      dialog.current.classList.remove("opening")
      // setIsOpen(true)
    }

    if (e.animationName === "hide") {
      dialog.current.classList.remove("closing")
      setIsOpen(false)
    }
  }

  return (
    <>
      <dialog
        ref={dialog}
        class="search"
        open={isOpen}
        onClose={onClose}
        onAnimationEnd={onAnimationEnd}
      >
        <div class="container">
          <div class="search__header">
            <input
              class="search__input"
              name="query"
              type="text"
              value={value}
              autofocus
              onInput={(e) => {
                setValue(e.target.value)
                onInput(e)
              }}
              placeholder="Was suchen Sie?"
            />
            <button
              type="button"
              class="search__close button button--icon"
              onClick={onClose}
            >
              <svg
                class="icon"
                aria-label="{% translate 'Close Search Overlay' %}"
                role="img"
              >
                <use xlinkHref="#icon-close" />
              </svg>
            </button>
          </div>
          <div
            class="search__results articles grid grid--gapped grid--reveal"
            dangerouslySetInnerHTML={{ __html: response }}
          />
        </div>
      </dialog>
    </>
  )
}

const debounce = (func, wait, immediate) => {
  let timeout

  return function (...args) {
    const later = () => {
      timeout = null
      if (!immediate) func.apply(this, args) // ...args ist jetzt ein echtes Array
    }

    const callNow = immediate && !timeout

    clearTimeout(timeout)
    timeout = setTimeout(later, wait)

    if (callNow) func.apply(this, args)
  }
}
