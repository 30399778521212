import { qs, qsa } from "./utils"

/**
 * Initialize map filtering functionality
 * @returns {void}
 */
export const initMapFilter = () => {
  // Get filter elements
  const filterParents = qsa("[data-filter-parent]")
  if (filterParents.length) {
    const filterChildren = qsa("[data-areal]")
    const mapPlans = qsa("[data-plan]")
    const mapPlansHover = qsa("[data-plan-hover]")
    const mapRealestateHover = qsa("[data-realestate-hover]")
    const mapFuture = qsa("[data-future]")
    const overlay = qs("[data-map-overlay]")
    const closeButton = qs("[data-close-overlay]")
    let activTheme = null

    // Remove future items immediately if no corresponding template exists
    for (const future of mapFuture) {
      const areal = future.dataset.future
      const templateExists = Boolean(qs(`[data-template="${areal}"]`, overlay))
      if (!templateExists) {
        future.remove()
      }
    }

    /**
     * Toggle hover state for a plan element
     * @param {string} slug - The plan identifier
     */
    const togglePlanHover = (slug) => {
      for (const plan of mapPlansHover) {
        if (plan.dataset.planHover === slug) {
          plan.classList.toggle("hideOpacity")
          plan.classList.toggle("addGlow")
          break
        }
      }
    }

    /**
     * Toggle hover state for a real estate element
     * @param {string} slug - The real estate identifier
     */
    const toggleRealestateHover = (slug) => {
      for (const child of mapRealestateHover) {
        if (slug === child.dataset.realestateHover) {
          child.classList.remove("hideOpacity")
        } else {
          child.classList.toggle("hideOpacity")
        }
      }
    }

    /**
     * Handles hover event on filter child elements
     * @param {MouseEvent} e - The event object
     */
    const filterEventHover = (e) => {
      e.preventDefault()
      e.currentTarget.classList.toggle("hover")
      if (activTheme === "plan") {
        togglePlanHover(e.currentTarget.dataset.areal)
      } else if (activTheme === "realestate") {
        toggleRealestateHover(e.currentTarget.dataset.areal)
      } else if (activTheme === "future") {
        // Future hover handled by CSS if needed
      }
    }

    /**
     * Handles hover event on map elements
     * @param {MouseEvent} e - The event object
     */
    const mapEventHover = (e) => {
      e.preventDefault()
      if (activTheme === "plan") {
        const plan = e.currentTarget.dataset.plan
        qs(`[data-plan-hover=${plan}]`)?.classList.toggle("hideOpacity")
        qs(`[data-plan-hover=${plan}]`)?.classList.toggle("addGlow")
        qs(`[data-areal=${plan}]`)?.classList.toggle("hover")
      } else if (activTheme === "realestate") {
        const realestate = e.currentTarget.dataset.realestateHover
        qs(`[data-areal=${realestate}]`)?.classList.toggle("hover")
        for (const child of mapRealestateHover) {
          if (realestate === child.dataset.realestateHover) {
            child.classList.remove("hideOpacity")
          } else {
            child.classList.toggle("hideOpacity")
          }
        }
      } else if (activTheme === "future") {
        // Future hover handled by CSS if needed
      }
    }

    /**
     * Clears the overlay content
     * @returns {void}
     */
    const clearOverlay = () => {
      const overlayContent = qs("[data-template-content]", overlay)
      if (overlayContent) overlayContent.remove()
    }

    /**
     * Closes the overlay on Escape key press
     * @param {KeyboardEvent} e - The event object
     */
    const keyBoardEvent = (e) => {
      if (e.key === "Escape") {
        closeOverlay()
      }
    }

    /**
     * Opens the overlay
     * @returns {void}
     */
    const openOverlay = () => {
      overlay.style.display = ""
      document.body.addEventListener("keydown", keyBoardEvent)
    }

    /**
     * Closes the overlay
     * @returns {void}
     */
    const closeOverlay = () => {
      overlay.style.display = "none"
      clearOverlay()
      document.body.removeEventListener("keydown", keyBoardEvent)
      delete overlay.dataset.overlayTheme
    }

    /**
     * Handles click event on filter children or map items
     * @param {MouseEvent} e - The event object
     */
    const eventClick = (e) => {
      e.preventDefault()
      clearOverlay()
      const data = e.currentTarget.dataset
      const areal =
        data?.areal || data?.plan || data?.realestateHover || data?.future
      const theme =
        data?.parent ||
        (data?.plan ? "plan" : undefined) ||
        (data?.realestateHover ? "realestate" : undefined) ||
        (data?.future ? "future" : undefined)

      // Disable realestate overlay as per original logic
      if (areal && theme !== "realestate") {
        const overlayTemplate = qs(`[data-template="${areal}"]`, overlay)
        // If no template found for future, do not open overlay
        if (theme === "future" && !overlayTemplate) {
          return
        }
        if (overlayTemplate) {
          const clone = document.importNode(overlayTemplate.content, true)
          overlay.firstElementChild.appendChild(clone)
        }

        overlay.dataset.overlayTheme = theme
        openOverlay()
      }
    }

    /**
     * Shows children of the selected filter parent and attaches click events
     * @param {string} parent - The filter parent identifier
     */
    const toggleFilterChildren = (parent) => {
      for (const child of filterChildren) {
        if (parent === child.dataset.parent) {
          child.classList.remove("hide")
          child.addEventListener("click", eventClick, false)
        } else {
          child.classList.add("hide")
          child.removeEventListener("click", eventClick, false)
        }
      }
    }

    /**
     * Handles click event on filter parent elements
     * @param {MouseEvent} e - The event object
     */
    const filterParentEvent = (e) => {
      e.preventDefault()
      closeOverlay()
      for (const parent of filterParents) {
        parent.classList.remove("active")
      }
      e.currentTarget.classList.add("active")
      toggleFilterChildren(e.currentTarget.dataset.filterParent)
      const theme = e.currentTarget.dataset.filterParent
      activTheme = theme
      const all = [...mapPlans, ...qsa("[data-future]"), ...mapRealestateHover]
      for (const item of all) {
        item.classList.add("hideOpacity")
        item.removeEventListener("mouseenter", mapEventHover, false)
        item.removeEventListener("mouseleave", mapEventHover, false)
        item.removeEventListener("click", eventClick, false)
      }

      if (theme === "plan") {
        for (const plan of mapPlans) {
          plan.classList.remove("hideOpacity")
          plan.addEventListener("mouseenter", mapEventHover, false)
          plan.addEventListener("mouseleave", mapEventHover, false)
          plan.addEventListener("click", eventClick, false)
        }
      } else if (theme === "realestate") {
        for (const realestate of mapRealestateHover) {
          realestate.classList.remove("hideOpacity")
          realestate.addEventListener("mouseenter", mapEventHover, false)
          realestate.addEventListener("mouseleave", mapEventHover, false)
          realestate.addEventListener("click", eventClick, false)
        }
      } else if (theme === "future") {
        // Only remaining future elements have templates
        const updatedMapFuture = qsa("[data-future]")
        for (const future of updatedMapFuture) {
          future.classList.remove("hideOpacity")
          future.addEventListener("click", eventClick, false)
        }
      }
    }

    // Add event listeners to filter parents
    for (const areal of filterParents) {
      areal.addEventListener("click", filterParentEvent, false)
    }

    // Close overlay event
    closeButton.addEventListener("click", closeOverlay)

    // Add hover events to filter children
    for (const filter of filterChildren) {
      filter.addEventListener("mouseenter", filterEventHover, false)
      filter.addEventListener("mouseleave", filterEventHover, false)
    }

    // Activate "plan" filter by default
    qs("[data-filter-parent='plan']").click()
  }
}
